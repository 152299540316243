@import "~rivals/styles/typography";
@import "~rivals/styles/variables";
@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";

$card-height: 106px;

.card {
  background-color: $white;
  border: 1px solid $sand-30;
  display: flex;
  height: $card-height;
  padding: $spacing-8 $spacing-10;
  position: relative;
  width: 100%;

  &.widget {
    padding: $spacing-8 0;

    .avatar {
      padding-right: $spacing-16;
    }

    .description {
      @include font-primary-body-B6;
    }
  }

  &.dark {
    background-color: transparent;
    border: none;
    height: auto;

    .date,
    .title,
    .messageContainer {
      color: $sand-30;
    }
  }
}

.title {
  @include font-primary-body-B7;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.image {
  height: 40px;
  width: 40px;

  &.circle {
    border-radius: 50%;
  }
}

.avatar {
  padding-right: $spacing-8;
}

.content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.description {
  @include font-primary-body-B5;
  flex: 1 1 auto;
  line-height: 15.6px;
  max-height: 56.55px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  // use webkit-line-clamp for the browsers that supports it (most modern browsers). To show ellipses for 3 lines
  @supports (-webkit-line-clamp: 3) {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: unset;
    word-wrap: break-word;
  }
}

.link {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: $spacing-4 0;
}

.messageContainer {
  @include font-primary-body-B7;
  display: flex;
  justify-content: space-between;

  span {
    padding: 0 $spacing-12;
  }

  .iconWrapper + .iconWrapper {
    padding-left: $spacing-16;
  }
}

.iconWrapper {
  display: flex;

  .messageIcon,
  .viewIcon {
    margin-right: $spacing-4;
  }
}

.date {
  @include font-primary-body-B7;
  color: $abyss;
}
