@import "~rivals/styles/breakpoints.scss";
@import "~rivals/styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  padding-top: $spacing-64;
  @include sizes-ending-with($screen-lg-mobile-max) {
    padding-top: 0;
  }
}

.loadingSpinner {
  height: 200px;
}
