@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

.module {
  align-items: center;
  background: mediumpurple;
  display: flex;
  justify-content: center;
  width: 100%;
}

.widget {
  height: $dashboard-widget-height;
  width: $dashboard-widget-width;
  @include sizes-between($screen-tablet-min, $screen-tablet-max) {
    width: $dashboard-tablet-widget-width;
  }
  @include sizes-ending-with($screen-lg-mobile-max) {
    width: $mobile-body-width;
  }
}

.mobileWidgetLabel {
  @include font-extended-headline-H5;
  line-height: 1;
  text-transform: uppercase;
}

.loadingContainer {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
