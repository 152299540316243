@import "~rivals/components/shared/Widgets/mixins";
@import "~rivals/styles/colors";
@import "~rivals/styles/typography";

.targetBody {
  background-color: $eye-black;
  border: 1px solid $eye-black;
  color: $bone;
  column-gap: $spacing-12;
  display: flex;
  flex-direction: row;
  height: 121px;
  justify-content: space-between;
  margin-bottom: $spacing-4;
  padding: $spacing-6;

  &.condensed {
    height: 98px;
  }

  &.darkMode {
    background-color: rgba($abyss, 0.7);
  }

  .prospectData {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 176px;
    order: 1;
    width: calc(100% - calc(98px + #{$spacing-12}));

    a {
      color: $bone;
    }

    .prospectBioData {
      @include font-primary-body-B4;
      align-items: center;
      display: grid;
      grid-template-columns: 54px calc(100% - 54px);

      &.condensed {
        @include font-primary-body-B5;
        line-height: 18px;
      }

      .lastName {
        @include font-primary-bold-SB4;
        line-height: 18px;

        &.condensed {
          @include font-primary-bold-SB5;
          line-height: 18px;
        }
      }

      .position {
        @include font-primary-body-B7;
        margin-top: $spacing-2;
      }
    }
  }

  .prospectStats {
    @include font-primary-bold-SB6;
    align-items: center;
    column-gap: $spacing-4;
    display: grid;
    grid-template-columns: 1.75fr 1fr 1.25fr 1fr;
    height: 100%;
    justify-items: center;
    text-align: center;
    width: 100%;

    &.condensed {
      grid-template-columns: 1fr 2fr 1.5fr;
    }
  }

  .forecastSpread {
    @include font-primary-body-B5;
    background-color: $mud;
    display: flex;
    height: 100%;
    order: 2;
    padding: 0 $spacing-12;
    width: 98px;

    &.darkMode {
      background-color: $eye-black;
    }

    .forecastedColleges {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 100%;
    }

    .forecast {
      display: grid;
      grid-template-columns: 36px calc(100% - 36px);
      line-height: 30px;
      text-align: center;
    }

    .maxForecasts {
      margin: $spacing-4 0;
    }

    .activeCollege {
      font-weight: bold;
    }
  }
}

.emptyStyles {
  align-items: center;
  display: flex;
}

.emptyFirstName {
  background-color: $mud;
  height: 14px;
  width: 99px;
}

.emptyLastName {
  background-color: $mud;
  height: 6px;
  margin-top: 12px;
  width: 50px;
}

.emptyStats {
  background-color: $mud;
  height: 6px;
  width: 80%;
}
