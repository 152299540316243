@import "~rivals/components/shared/Widgets/mixins";

ul.teamRankingsList {
  margin-bottom: 0;
  padding-inline-start: 0;
}

.titleSelect {
  @include widget-header-title-select;
  // The !important is needed so the hidden dropdown from Ant Design Select is positioned correctly
  height: 50px !important; //stylelint-disable-line declaration-no-important

  :global {
    .ant-select-selection-search-input {
      height: 50px !important; //stylelint-disable-line declaration-no-important
    }
  }
}

.optionItem {
  @include widget-header-option-item;
}

.optionName {
  @include widget-header-option-name;
}

.logoAndTitleWrapper {
  align-items: center;
  column-gap: 6px;
  display: grid;
  grid-template-columns: 36px 1fr;
  padding: $spacing-16 $spacing-4;
  pointer-events: none;
  position: relative;
  z-index: 2;

  svg {
    height: 36px;
    width: 36px;
  }
}

.dataRow,
.titleRow {
  grid-template-columns: 34px 40px 1fr;
}
