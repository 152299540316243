@import "~rivals/styles/colors";
@import "~rivals/styles/typography";

.prospectRank {
  color: $abyss-10;
  height: 26px;
  text-transform: uppercase;

  a {
    color: $abyss-10;
  }

  .rank {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.rankChange {
  display: flex;
  justify-content: center;
}

.arrowIcon {
  padding-right: 3px;
}

.rankName {
  @include font-primary-body-B8;
}
