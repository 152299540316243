@import "~rivals/styles/typography";

.tooltipTrigger {
  cursor: pointer;
  height: 18px;
  width: 18px;
}

.toolTip {
  :global(.ant-tooltip-inner) {
    @include font-primary-body-B6;
  }
}
