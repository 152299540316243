.loadingContainer,
.topTargetsMobileLoadingContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.loadingContainer {
  height: 100%;
}

/* The height for top targets on mobile is different from the rest */

.topTargetsMobileLoadingContainer {
  height: 200px;
}
