@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

/**
  Not all cases are covered, the rest should be covered in cooperation with design
  TODO: https://griosf.atlassian.net/browse/RVLS-9261
**/

$ink-bar-height: 3px;

.wrapper.line {
  .tabs {
    :global {
      .ant-tabs-nav:before {
        border-bottom: $ink-bar-height solid $sand-30;
      }

      .ant-tabs-tab {
        margin-left: $spacing-24;
        padding: $spacing-12 $spacing-16;

        * {
          color: $rolling-stone;
        }
      }

      .ant-tabs-tab-active * {
        color: $rivals-blue;
      }

      .ant-tabs-ink-bar {
        background: $rivals-blue;
        height: $ink-bar-height;
      }
    }
  }
}

.wrapper.card.dark {
  :global {
    .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap {
      white-space: unset;
    }

    .ant-tabs-card .ant-tabs-nav {
      &::before {
        display: none;
      }

      .ant-tabs-nav-list {
        width: 100%;
      }

      .ant-tabs-tab {
        border: none;
        flex: 1;
        justify-content: center;
        padding: $spacing-12 $spacing-16;
        @include sizes-ending-with($screen-lg-mobile-max) {
          padding: $spacing-12 0;
        }

        * {
          color: $bone;
        }

        &.ant-tabs-tab-active {
          background-color: rgba($abyss, 0.7);
          border-radius: 0;
        }

        &:not(.ant-tabs-tab-active) {
          background-color: rgba($clay-50, 0.3);
        }

        .ant-tabs-tab-btn {
          line-height: 1;
          text-align: center;
        }
      }
    }
  }
}
