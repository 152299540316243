@import "~rivals/styles/typography";
@import "~rivals/styles/variables";
@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";

$card-height: 106px;
$card-width: 344px;

.container {
  max-width: $homepage-main-content-width;
  width: 100%;

  &.nationals {
    padding: $spacing-24 $spacing-12 0;

    .title,
    .actionTitle {
      @include font-extended-extrabold-A3;
      color: $bone-10;
    }
  }

  &.dark {
    color: $bone-10;

    .cardContainer {
      border-bottom: 1px solid $clay-50;
    }
  }

  &.widget {
    height: $dashboard-widget-height;
    max-width: unset;

    .cardsContainer {
      display: flex;
      flex-direction: column;
      padding: 0;
      row-gap: 0;
    }

    .siteTitle {
      padding: 0;
    }

    .siteTitleContent {
      @include font-extended-headline-H4;
      padding: $spacing-12;
    }

    .cardContainer {
      margin: 0 $spacing-12;

      &:last-of-type {
        border-bottom: none;
      }
    }

    .visitContainer {
      padding: 0;
    }
  }
}

.cardsContainer {
  column-gap: $spacing-12;
  display: grid;
  grid-template-columns: $card-width $card-width;
  grid-template-rows: $card-height $card-height $card-height;
  padding-top: $spacing-24;
  row-gap: $spacing-8;
  width: 100%;

  &.twoRow {
    column-gap: $spacing-4;
    grid-template-columns: $mobile-body-width $mobile-body-width;
    grid-template-rows: $card-height $card-height;
    padding-top: $spacing-12;
  }
  @include sizes-ending-with($screen-lg-mobile-max) {
    display: flex;
    flex-direction: column;
    padding-top: $spacing-12;
  }
}

.padding {
  padding-top: $spacing-8;
}

.visitContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: $spacing-24 0;
}

.teamSelectContainer {
  display: flex;
  flex-direction: column;
  padding: $spacing-36 0;
}

.teamSelector {
  display: flex;
  padding: $spacing-12 0;
  width: 100%;
}

.ctaButton {
  @include sizes-ending-with($screen-lg-mobile-max) {
    width: 100%;
  }
}

.title,
.actionTitle {
  @include font-extended-extrabold-A2;
}

.siteTitle {
  @include font-extended-headline-H5;
  color: $bone-10;
  padding: $spacing-12;
  text-transform: uppercase;
}

.schoolHighlight {
  height: $spacing-8;
  width: 100%;
}
