@import "~rivals/styles/colors";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";
@import "~rivals/styles/exports.module.scss";
@import "~rivals/components/shared/Widgets/mixins";

$tooltip-icon-size: 12px;

ul.topTargetsList {
  list-style-type: none;
  margin: 0;
  margin-bottom: 20px;
  margin-top: $spacing-8;
  padding: 0;
}

.scrollButton {
  background-color: $mud-90;
  border: none;
  color: $bone;
  cursor: pointer;
  height: 28px;
  text-transform: uppercase;
  width: 100%;

  &:hover:enabled,
  &:active:enabled {
    background-color: $mud-70;
  }

  &.standardHeightScrollButton {
    height: 36px;
  }

  &.disabled {
    color: $abyss-50;
    cursor: not-allowed;
  }
}

.buttonContent {
  @include font-primary-body-B6;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
}

.buttonIcon {
  align-content: center;
}

.buttonText {
  padding-left: 6px;
}

.bottomButtonWrapper {
  margin-top: $spacing-4;
}

.topButtonWrapper {
  margin-bottom: $spacing-4;
  margin-top: -$spacing-8;
}

.condensedSlider {
  :global {
    .slick-list {
      // shortens the height of the slider slides so buttons can reach full height without overflowing the container
      // important required to override the inline style
      /* stylelint-disable-next-line declaration-no-important */
      height: 191px !important;
    }
  }
}

.tooltipStyles {
  height: $tooltip-icon-size;
  vertical-align: super;
  width: $tooltip-icon-size;
}

.toolTipText {
  @include font-primary-body-B6;

  .toolTipBold {
    @include font-primary-bold-SB6;
  }
}
