@import "~rivals/styles/colors";

.container {
  border: 1px solid $clay-50;
  height: 379px;
  width: 100%;

  :global {
    .ant-tabs-tabpane {
      height: 341px;
    }

    .ant-tabs-top .ant-tabs-nav {
      height: 36px;
      margin-bottom: 0;
    }

    // https://github.com/ant-design/ant-design/issues/43541

    .ant-tabs-nav-operations {
      display: none !important; //stylelint-disable-line declaration-no-important
    }
  }
}
