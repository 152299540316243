@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";
@import "~rivals/styles/variables";

$mobile-horizontal-margin: $spacing-24;

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.grid {
  column-gap: $spacing-24;
  display: grid;
  grid-template-areas: "leftRail body rightRail";
  grid-template-columns: $rail-width $body-width $rail-width;
  @include sizes-ending-with($screen-lg-mobile-max) {
    column-gap: 0;
    grid-template-areas:
      "body"
      "leftRail"
      "rightRail";
    grid-template-columns: auto;
    margin: 0 $spacing-12;
    max-width: calc(100% - 2 * $spacing-12);
  }
  @include sizes-between($screen-tablet-min, $screen-tablet-max) {
    column-gap: 0;
    grid-template-areas:
      "body"
      "leftRail"
      "rightRail";
    grid-template-columns: $body-width;
  }
  @include sizes-between(
    $screen-sm-desktop-min,
    $container-width-desktop-XL - 1
  ) {
    grid-template-areas:
      "leftRail body"
      "rightRail body"
      ". body";
    grid-template-columns: $rail-width $body-width;
  }
}

.body {
  display: flex;
  flex-direction: column;
  grid-area: body;
}

.notFirstArticle {
  margin-top: $spacing-32;
  @include sizes-ending-with($screen-lg-mobile-max) {
    margin-top: 0;
  }
}

.blocks {
  max-width: calc(100vw - $mobile-horizontal-margin);
  width: 100%;
}

.preview {
  margin-bottom: -$spacing-48;
  mask-image: linear-gradient(to bottom, $black 0%, $black 0%, transparent 90%);
}

.rail {
  display: flex;
  height: 100%;
  @include sizes-starting-with($screen-med-desktop-min) {
    align-self: start;
    height: unset;
    position: sticky;
    top: 100px;
  }

  &.left {
    flex-direction: column;
    grid-area: leftRail;
    row-gap: $spacing-24;
    @include sizes-ending-with($screen-tablet-max) {
      padding: $spacing-24 0;
    }
    @include sizes-starting-with($screen-sm-desktop-min) {
      margin-top: $spacing-12;
    }
    @include sizes-ending-with($screen-lg-mobile-max) {
      align-items: center;
      row-gap: $spacing-12;
    }
  }

  &.right {
    flex-direction: column;
    grid-area: rightRail;
    justify-content: flex-start;
    row-gap: $spacing-24;
    @include sizes-starting-with($screen-sm-desktop-min) {
      margin-top: $spacing-12;
    }
    @include sizes-ending-with($screen-lg-mobile-max) {
      align-items: center;
      row-gap: $spacing-12;
    }
  }

  .loadingContainer {
    margin-top: 24px;
  }
}
