@use "~rivals/styles/typography" as *;

@use "~rivals/styles/variables" as *;

@use "~rivals/styles/breakpoints" as *;

.wrapper {
  position: relative;
  @include sizes-ending-with($screen-tablet-max) {
    max-width: $homepage-main-content-width;
  }

  &.sticky {
    @include sizes-starting-with($screen-sm-desktop-min) {
      position: sticky;
      top: $header-height-total;
    }
  }
}

.mobileTablet {
  max-width: $homepage-main-content-width;
  width: 100%;
}

.newsWidgetTitle {
  @include font-primary-bold-SB4;
  margin-bottom: $spacing-16;
  text-transform: uppercase;
}

.cardWrapper {
  &:not(:first-of-type) {
    margin-top: $spacing-12;
  }

  .contentCard {
    // overrides the contentCard max-width to match its parent
    max-width: 100%;

    & h3 {
      @include font-primary-body-B5;
    }

    & div,
    span {
      @include font-primary-body-B6;
    }
  }
}

.moreNewsButton {
  margin-top: $spacing-24;
  text-transform: capitalize;
}

.loadingContainer {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-top: 24px;
  width: 100%;
}
