@import "~rivals/styles/breakpoints";
@import "~rivals/styles/hide-elements";
@import "~rivals/styles/variables";

$large-body-width: 700px;

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

@mixin grid-details {
  column-gap: $spacing-24;
  display: grid;
  grid-template-columns: auto $rail-width $large-body-width $rail-width auto;
  row-gap: $spacing-24;
  width: 100%;
  @include sizes-ending-with($screen-tablet-max) {
    grid-template-columns: 100% 0;
    margin: 0 $spacing-12;
    width: auto;
  }
  @include sizes-between($screen-sm-desktop-min, $screen-sm-desktop-max) {
    grid-template-columns: auto $rail-width $large-body-width auto;
  }
}

.contentGrid {
  @include grid-details;
  grid-template-areas: ". leftRail main rightRail .";
  grid-template-rows: 1fr;
  margin-top: $spacing-36;
  @include sizes-between($screen-sm-desktop-min, $screen-sm-desktop-max) {
    // TODO: double check the stacking order of the rails
    grid-template-areas:
      ". leftRail main ."
      ". rightRail main .";
  }
}

.dashboardGrid {
  @include grid-details;
  grid-template-areas: "dashboard dashboard dashboard dashboard dashboard";
  grid-template-rows: auto;
  @include sizes-ending-with($screen-tablet-max) {
    grid-template-areas: "dashboard";
    grid-template-rows: auto;
  }
  @include sizes-between(
    $screen-sm-desktop-min,
    $container-width-desktop-XL - 1
  ) {
    grid-template-areas: "dashboard dashboard dashboard dashboard";
  }
}

.main {
  display: flex;
  flex-direction: column;
  grid-area: main;
  grid-row-gap: $spacing-36;
  @include hide-on-tablet-and-mobile; // TODO: Investigate using Media component to hide and show wrapped server components

  &.mobile {
    align-items: center;
    display: flex;
    grid-area: mainMobile;
    grid-row-gap: $spacing-12;
    margin: $spacing-12 $spacing-12 0 $spacing-12;
    @include hide-on-desktop;
  }
}

.rail {
  display: flex;
  flex-direction: column;
  grid-row-gap: $spacing-24;
  height: 100%;
  @include hide-on-tablet-and-mobile;

  &.left {
    grid-area: leftRail;
  }

  &.right {
    grid-area: rightRail;
  }
}

.module {
  align-items: center;
  display: flex;
  height: 354px;
  justify-content: center;
  margin: $spacing-12;
}
