.sponsorContainer {
  display: flex;
  height: 20px;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.sponsorImage {
  margin-top: auto;
}
