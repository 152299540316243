@import "~rivals/styles/hide-elements.scss";
@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

.horizontalContainer,
.rectangularContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.horizontalContainer {
  margin: 10px auto;
  @include sizes-starting-with($screen-tablet-min) {
    .adContainer {
      min-height: 90px;
      min-width: 728px;
    }
  }
  @include sizes-ending-with($screen-mobile-max) {
    .adContainer {
      min-height: 50px;
      min-width: 320px;
    }
  }

  div:global(.benji-premium-ad) {
    iframe {
      margin: 0 calc(50% - 50vw);
    }
  }
}

.horizontalLargeContainer {
  @include hide-on-mobile;
}

.rectangularContainer {
  .adContainer {
    min-height: 250px;
    width: 300px;
  }
}

.adContainer {
  @include font-yahoo-b6;
  align-items: center;
  background-color: $gray90;
  color: $gray60;
  display: flex;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
}

.adPlaceholder {
  // prevents placeholder text from getting crammed against the side while the ad is being injected
  position: absolute;
}

.forceAdToFront {
  margin-bottom: -10px;
  z-index: calc($player-block-wrapper-z-index + 1);
}
